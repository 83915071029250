import Swiper from "swiper";

function swiper($selector, $options) {
  return new Swiper($selector, $options);
}

swiper(".latest-blog__swiper", {
  speed: 800,
  slidesPerView: 1,
  spaceBetween: 30,
  autoplay: {
    delay: 3000
  },
  pagination: {
    el: ".swiper-pagination",
    dynamicBullets: true,
    clickable: true
  },

  breakpoints: {
    768: {
      slidesPerView: 2,
    },
    1200: {
      slidesPerView: 3,
    }
  }
});



// var galleryThumbs = new Swiper('.tabs-buttons', {
//   spaceBetween: 10,
//   slidesPerView: 'auto',
//   freeMode: true,
//   watchSlidesVisibility: true,
//   watchSlidesProgress: true,
// });
// var galleryTop = new Swiper('.tabs-content', {
//   spaceBetween: 10,
//   thumbs: {
//     swiper: galleryThumbs
//   }
// });

// document.querySelectorAll('.tabs-buttons').forEach(buttons => {
//   new Swiper(buttons, {
//     spaceBetween: 10,
//     thumbs: {
//       swiper: /** select next .tabs-content element here */
//     }
//   })
// })



document.querySelectorAll('.tabs-content').forEach(content => {
  const buttons = new Swiper(content.previousElementSibling, {
    spaceBetween: 10,
    slidesPerView: 'auto',
    freeMode: true,
    watchSlideVisibility: true,
    watchSlideProgress: true,
  })
  new Swiper(content, {
    spaceBetween: 10,
    thumbs: {
      swiper: buttons
    }
  })
})